<template>
  <div>
    <!-- picAF73A360A6B1F3BE43A9C1587A87D066.jpg -->
    <img class="rounded img-large" src="">

    <p>
      We recently showed fans an outline of an upcoming LEGO Universe character and asked if you could guess what the character does for a living. LEGO Universe newsletter subscribers first learned the answer a few weeks ago, and now it's time to let everyone else in on the secret!
    </p>
    <p>
      There was a ton of talk about what the van in the background could be for and what the mystery-man might be holding (no, that's not an ice cream cone!), but
      <router-link :to="{ name: 'creation-lab-member', params: {memberId: 'c70ce674-e89e-482d-aad3-3b1e6d21866a'} }">Ryo!</router-link> was the very first LEGO Universe fan to guess right when he said the character could be a news reporter!
    </p>
    <p>
      We'll soon be hearing more from our new news reporter as he tries to solve some of the mysteries of LEGO Universe!
    </p>
    <p>
      Be sure to
      <router-link :to="{ name: 'newsletter' }">sign up</router-link> for the free LEGO Universe email newsletter if you haven't already! The newsletter is your inside source for exclusive VIP gifts and sneak peeks at the game's development!
    </p>
  </div>
</template>
